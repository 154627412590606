import UpperHeader from './UpperHeader';
import logo from '@/public/img/logo.jpg';
import Image from 'next/image';
import Link from 'next-translate-routes/link';
import { useTranslation } from 'next-i18next';
import { scrollToAnchor } from 'utils/scrollToAnchor';
import { useEffect, useState } from 'react';
import router from 'next-translate-routes/react/router';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';

const Header = () => {
  const MobileMenu = dynamic(() => import('./MobileMenu'));

  const { t } = useTranslation('common');
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const toggleMobileMenu = () => {
    setMobileMenuIsOpen(!mobileMenuIsOpen);
  };

  useEffect(() => {
    if (mobileMenuIsOpen) {
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
      document.ontouchmove = function (e) {
        e.preventDefault();
      };
    } else {
      document.documentElement.style.overflow = 'unset';
      document.body.style.overflow = 'unset';
      // Enable scrolling.
      document.ontouchmove = function () {
        return true;
      };
    }
  }, [mobileMenuIsOpen]);
  const nextRouter = useRouter();
  let { locale: activeLocale } = nextRouter;
  const { pathname, query, asPath } = nextRouter;
  // const changeLocale = (locale: string) => {
  //   // i18n.changeLanguage(locale);
  //   router.push(
  //     {
  //       //@ts-ignore
  //       route: router.pathname,
  //       query: router.query,
  //     },
  //     router.asPath,
  //     { locale }
  //   );
  // };

  return (
    <>
      <header>
        <UpperHeader />
        <div className='bg-[#131313] py-6 border-b-2 border-[#FFFFFF3D]'>
          <div className='container mx-auto flex items-center justify-between px-4'>
            <Link href='/'>
              <Image src={logo} alt='' width={70} height={70}></Image>
            </Link>
            <nav className=' text-white gap-x-10 hidden md:flex'>
              <Link onClick={(e) => scrollToAnchor(e, '#rezervace')} href='#rezervace'>
                {t('header.menu.reservation')}
              </Link>
              <Link href='/priceList'>{t('header.menu.pricing')}</Link>
              <Link href='/specialOffers'>{t('header.menu.specialOffers')}</Link>
              <Link href='/priceList'>{t('header.menu.gallery')}</Link>
              <Link href='/contact'>{t('header.menu.contact')}</Link>
              <Link href={{ pathname, query }} locale={activeLocale === 'cs' ? 'en' : 'cs'}>
                {activeLocale === 'cs' ? (
                  <svg className='' width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M28 4.66663H0V23.3335H28V4.66663Z' fill='#F0F0F0' />
                    <path d='M15.75 4.6665H12.25V12.2498H0V15.7498H12.25V23.3331H15.75V15.7498H28V12.2498H15.75V4.6665Z' fill='#D80027' />
                    <path d='M21.5352 17.2461L28 20.8377V17.2461H21.5352Z' fill='#0052B4' />
                    <path d='M17.0435 17.2461L28 23.333V21.6118L20.1418 17.2461H17.0435Z' fill='#0052B4' />
                    <path d='M25.0815 23.3331L17.0435 18.8671V23.3331H25.0815Z' fill='#0052B4' />
                    <path d='M17.0435 17.2461L28 23.333V21.6118L20.1418 17.2461H17.0435Z' fill='#F0F0F0' />
                    <path d='M17.0435 17.2461L28 23.333V21.6118L20.1418 17.2461H17.0435Z' fill='#D80027' />
                    <path d='M4.94052 17.246L0 19.9907V17.246H4.94052Z' fill='#0052B4' />
                    <path d='M10.9565 18.02V23.333H1.39404L10.9565 18.02Z' fill='#0052B4' />
                    <path d='M7.85821 17.2461L0 21.6118V23.333L10.9565 17.2461H7.85821Z' fill='#D80027' />
                    <path d='M6.46488 10.7534L0 7.16174V10.7534H6.46488Z' fill='#0052B4' />
                    <path d='M10.9565 10.7534L0 4.6665V6.38774L7.85821 10.7534H10.9565Z' fill='#0052B4' />
                    <path d='M2.91846 4.6665L10.9565 9.1325V4.6665H2.91846Z' fill='#0052B4' />
                    <path d='M10.9565 10.7534L0 4.6665V6.38774L7.85821 10.7534H10.9565Z' fill='#F0F0F0' />
                    <path d='M10.9565 10.7534L0 4.6665V6.38774L7.85821 10.7534H10.9565Z' fill='#D80027' />
                    <path d='M23.0594 10.7536L28 8.00879V10.7536H23.0594Z' fill='#0052B4' />
                    <path d='M17.0435 9.97945V4.6665H26.6059L17.0435 9.97945Z' fill='#0052B4' />
                    <path d='M20.1418 10.7534L28 6.38774V4.6665L17.0435 10.7534H20.1418Z' fill='#D80027' />
                  </svg>
                ) : (
                  <svg xmlns='http://www.w3.org/2000/svg' enableBackground='new 0 0 512 512' height='512' viewBox='0 0 512 512' width='512'>
                    <g id='_x35_8_x2C__Czech_Republic_x2C__country_x2C__national_x2C__flag_x2C__world_flag'>
                      <g id='XMLID_684_'>
                        <path id='XMLID_325_' d='m16 81 119.998 110 91.335 60 128.667 10 140-10v-30-10-60-70z' fill='#f8fafc' />
                        <path id='XMLID_324_' d='m16 431h480v-70-70-40h-268.667l-82.185 60z' fill='#db002a' />
                        <path id='XMLID_323_' d='m16 431v-350l211.333 170z' fill='#0053b5' />
                      </g>
                    </g>
                  </svg>
                )}
              </Link>
            </nav>
            <button
              className={`hamburger hamburger--squeeze ${mobileMenuIsOpen ? 'is-active' : ''} flex md:hidden outline-none`}
              aria-label='mobile menu opener'
              type='button'
              onClick={toggleMobileMenu}>
              <span className='hamburger-box'>
                <span className='hamburger-inner' />
              </span>
            </button>
          </div>
        </div>
      </header>
      <MobileMenu isOpen={mobileMenuIsOpen} toggleMobileMenu={toggleMobileMenu} />
    </>
  );
};

export default Header;
