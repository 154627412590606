import '../styles/globals.css';
import type { AppProps } from 'next/app';
import Header from '../components/header/Header';
import { appWithTranslation } from 'next-i18next';
import { withTranslateRoutes } from 'next-translate-routes';
import 'react-widgets/styles.css';
import Footer from 'components/footer/Footer';
import { DefaultSeo } from 'next-seo';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import Head from 'next/head';
import '../styles/hamburger.css';
import { ReCaptchaProvider } from 'next-recaptcha-v3';

const App = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GTM_ID && process.env.ENVIRONMENT === 'production') {
      TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID || '' });
    }
  }, []);
  return (
    <>
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <link rel='icon' href='/favicon.ico' />
      </Head>
      <DefaultSeo
        titleTemplate='%s | Hodinový hotel Exclusive'
        description=''
        openGraph={{
          images: [
            {
              url: '',
              type: 'image/jpeg',
            },
          ],
        }}
      />
      <Header />
      <ReCaptchaProvider>
        <Component {...pageProps} />
      </ReCaptchaProvider>
      <Footer></Footer>
    </>
  );
};

export default withTranslateRoutes(JSON.parse(`{"defaultLocale":"cs","locales":["cs","en"],"routesTree":{"name":"","paths":{"default":""},"children":[{"name":"contact","paths":{"default":"contact","cs":"kontakt","en":"contact"}},{"name":"index","paths":{"default":"index"}},{"name":"priceList","paths":{"default":"priceList","cs":"cenik","en":"pricing"}},{"name":"specialOffers","paths":{"default":"specialOffers","cs":"akcni-nabidky","en":"special-offers"}}]}}`), appWithTranslation(App));
