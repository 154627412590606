import { useTranslation } from 'next-i18next';

const UpperHeader = () => {
  const { t } = useTranslation('common');
  return (
    <div className='bg-yellow hidden md:flex py-7'>
      <div className='container mx-auto px-4 flex flex-col md:flex-row justify-between'>
        <span className='text-black font-semibold mb-4 xl:mb-0'>{t('header.title')}</span>
        <div className='flex flex-col xl:flex-row'>
          <a href='tel:+420722413145' className='flex align-middle text-black mb-4 xl:mb-0 xl:mr-8 font-semibold'>
            <svg className='mr-3' width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M14.353 2.5C18.054 2.911 20.978 5.831 21.393 9.532'
                stroke='black'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M14.353 6.04297C16.124 6.38697 17.508 7.77197 17.853 9.54297'
                stroke='black'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M11.0315 12.4724C15.0205 16.4604 15.9254 11.8467 18.4653 14.3848C20.9138 16.8328 22.3222 17.3232 19.2188 20.4247C18.8302 20.737 16.3613 24.4943 7.68447 15.8197C-0.993406 7.144 2.76157 4.67244 3.07394 4.28395C6.18377 1.17385 6.66682 2.58938 9.11539 5.03733C11.6541 7.5765 7.04254 8.48441 11.0315 12.4724Z'
                stroke='black'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            +420 722 413 145
          </a>
          <span className='flex align-middle text-black font-semibold'>
            <svg className='mr-3' width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <g clipPath='url(#clip0_84_7181)'>
                <path
                  d='M16.2756 14.1192L12.9294 11.6096V6.49728C12.9294 5.98325 12.5139 5.56775 11.9998 5.56775C11.4858 5.56775 11.0703 5.98325 11.0703 6.49728V12.0744C11.0703 12.3672 11.2079 12.6432 11.4421 12.818L15.1602 15.6065C15.3275 15.732 15.5227 15.7925 15.7169 15.7925C16.0004 15.7925 16.2793 15.6651 16.4615 15.4197C16.7702 15.0097 16.6865 14.4269 16.2756 14.1192Z'
                  fill='black'
                />
                <path
                  d='M12 0C5.3828 0 0 5.3828 0 12C0 18.6172 5.3828 24 12 24C18.6172 24 24 18.6172 24 12C24 5.3828 18.6172 0 12 0ZM12 22.141C6.40898 22.141 1.85902 17.591 1.85902 12C1.85902 6.40898 6.40898 1.85902 12 1.85902C17.592 1.85902 22.141 6.40898 22.141 12C22.141 17.591 17.591 22.141 12 22.141Z'
                  fill='black'
                />
              </g>
              <defs>
                <clipPath id='clip0_84_7181'>
                  <rect width='24' height='24' fill='white' />
                </clipPath>
              </defs>
            </svg>
            {t('header.nonstop')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default UpperHeader;
