import Image from 'next/image';
import logo from '@/public/img/logo.jpg';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { useRouter } from 'next/router';
const Footer = () => {
  const { t } = useTranslation('common');
  const mapStyles = [
    {
      featureType: 'administrative',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ];

  const [contentStringName, setContentStringName] = useState(t('common:header.title'));

  const nextRouter = useRouter();
  let { locale: activeLocale } = nextRouter;
  useEffect(() => {
    if (activeLocale === 'en') {
      setContentStringName('Hodinový hotel Exclusive');
    } else {
      setContentStringName('Hourly hotel Exclusive');
    }
  }, [activeLocale]);

  useEffect(() => {
    const contentString =
      '<div id="content">' +
      '<div id="siteNotice">' +
      '</div>' +
      `<h1 id="firstHeading" class="firstHeading font-bold text-xl mb-2">${contentStringName}</h1>` +
      '<div id="bodyContent">' +
      '<p>Voroněžská 26<br>101 00 Praha 10</p>' +
      '</div>' +
      '</div>';
    const loader = new Loader({
      apiKey: process.env.NEXT_PUBLIC_GOOGLE_API_KEY || '',
      version: 'weekly',
    });
    const mapOptions = { center: { lat: 50.071571, lng: 14.446476 }, zoom: 18, disableDefaultUI: true, styles: mapStyles };
    loader
      .load()
      .then((google) => {
        const map = new google.maps.Map(document.getElementById('map'), mapOptions);
        // @ts-ignore
        const marker = new window.google.maps.Marker({
          position: { lat: 50.071571, lng: 14.446476 },
          map,
        });
        const infowindow = new google.maps.InfoWindow({
          content: contentString,
          ariaLabel: 'Uluru',
        });
        infowindow.open({
          anchor: marker,
          map,
        });
        marker.addListener('click', () => {
          infowindow.open({
            anchor: marker,
            map,
          });
        });
      })
      .catch((e) => {
        // do something
      });
  }, [activeLocale]);
  return (
    <footer className='bg-black py-10'>
      <div className='container mx-auto px-4 flex flex-col xl:flex-row border-b border-[#FFFFFF7A] pb-10 mb-10'>
        <div className='flex flex-col mb-10 xl:mb-0'>
          <Image className='mb-10' src={logo} alt='' width={70} height={70}></Image>
          <h3 className='text-white text-2xl max-w-[274px] font-bold mb-6'>{t('footer.title')}</h3>
          <a className='text-white flex mb-6' href='tel:+420722413145'>
            <svg className='mr-2' width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M14.353 2.5C18.054 2.911 20.978 5.831 21.393 9.532'
                stroke='#F0B10D'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M14.353 6.04297C16.124 6.38697 17.508 7.77197 17.853 9.54297'
                stroke='#F0B10D'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M11.0315 12.4724C15.0205 16.4604 15.9254 11.8467 18.4653 14.3848C20.9138 16.8328 22.3222 17.3232 19.2188 20.4247C18.8302 20.737 16.3613 24.4943 7.68447 15.8197C-0.993406 7.144 2.76157 4.67244 3.07394 4.28395C6.18377 1.17385 6.66682 2.58938 9.11539 5.03733C11.6541 7.5765 7.04254 8.48441 11.0315 12.4724Z'
                stroke='#F0B10D'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            +420 722 413 145
          </a>
          <a className='text-white flex mb-6' href='mailto:info@azylpromilencevpraze.cz'>
            <svg className='mr-2' width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M17.9026 8.8512L13.4593 12.4642C12.6198 13.1302 11.4387 13.1302 10.5992 12.4642L6.11841 8.8512'
                stroke='#F0B10D'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M16.9089 21C19.9502 21.0084 22 18.5095 22 15.4384V8.57001C22 5.49883 19.9502 3 16.9089 3H7.09114C4.04979 3 2 5.49883 2 8.57001V15.4384C2 18.5095 4.04979 21.0084 7.09114 21H16.9089Z'
                stroke='#F0B10D'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            info@azylpromilencevpraze.cz
          </a>
          <span className='text-white flex'>
            <svg className='mr-2' width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M14.5 10.5005C14.5 9.11924 13.3808 8 12.0005 8C10.6192 8 9.5 9.11924 9.5 10.5005C9.5 11.8808 10.6192 13 12.0005 13C13.3808 13 14.5 11.8808 14.5 10.5005Z'
                stroke='#F0B10D'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M11.9995 21C10.801 21 4.5 15.8984 4.5 10.5633C4.5 6.38664 7.8571 3 11.9995 3C16.1419 3 19.5 6.38664 19.5 10.5633C19.5 15.8984 13.198 21 11.9995 21Z'
                stroke='#F0B10D'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            Voroněžská 26 <br></br> 101 00 Praha 10
          </span>
        </div>
        <div className='w-full xl:pl-40'>
          <div id='map' className='h-96'></div>
        </div>
      </div>
      <div className='container mx-auto px-4 flex flex-col justify-center items-center md:flex-row md:justify-between'>
        <span className='text-[#FFFFFFCC] text-sm'>© Copyright 2023 {t('footer.title')}</span>
        <span className='text-[#FFFFFFCC] text-sm text-right'>
          {t('footer.createdBy')}{' '}
          <a className='hover:text-white transition-colors text-sm' href='https://wavedigital.cz'>
            WaveDigital
          </a>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
